.mainContainer {
  margin-top: 5px;
  padding: 10px 30px;
  .row {
    .stripContainer {
      margin: 0%;
      padding: 0%;
      .strip {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }
    }
    .col-12 {
      .title {
        font-family: "Alegreya Sans SC", sans-serif;
        color: #00897b;
        font-size: 2.3em;
        border-bottom: 1px solid #999;
        margin-left: 30px;
      }
    }
  }
}
.fixed-bottom {
  height: 30px;
  .nav {
    padding: 0% !important;
  }
}
